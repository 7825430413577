<template>
    <div class="class">
        <el-dialog v-model="dialogFormVisible" :title="title" width="50%" @close="$emit('close')">
            <span class="warn"
                ><el-alert
                    title="温馨提醒：涉密信息请进入政务内网数字统战填写"
                    :closable="false"
                    type="warning"
                    show-icon
            /></span>
            <el-form :model="form" class="form" :rules="rules" ref="ruleForm">
                <el-row :gutter="20">
                    <el-col :span="16">
                        <el-form-item label="年度：" prop="actionYear" :label-width="formLabelWidth">
                            <el-select v-model="form.actionYear">
                                <el-option
                                    :label="new Date().getFullYear() - 1"
                                    :value="new Date().getFullYear() - 1"
                                ></el-option>
                                <el-option
                                    :label="new Date().getFullYear()"
                                    :value="new Date().getFullYear()"
                                ></el-option>
                                <el-option
                                    :label="new Date().getFullYear() + 1"
                                    :value="new Date().getFullYear() + 1"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="牵头单位" prop="actionDeptId" :label-width="formLabelWidth">
                            <el-cascader
                                @change="handleChange"
                                ref="cascader"
                                filterable
                                clearable
                                v-model="form.actionDeptId"
                                :props="defaultProps"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="重大任务名称" prop="actionName" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" v-model="form.actionName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="目标体系" prop="actionTarget" :label-width="formLabelWidth">
                            <el-input
                                type="textarea"
                                rows="2"
                                placeholder="请输入"
                                v-model="form.actionTarget"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="工作体系" prop="actionWork" :label-width="formLabelWidth">
                            <el-input
                                type="textarea"
                                rows="2"
                                placeholder="请输入"
                                v-model="form.actionWork"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="政策体系" prop="actionPolicy" :label-width="formLabelWidth">
                            <el-input
                                type="textarea"
                                rows="2"
                                placeholder="请输入"
                                v-model="form.actionPolicy"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="评价体系" prop="actionEvaluation" :label-width="formLabelWidth">
                            <el-input
                                type="textarea"
                                rows="2"
                                placeholder="请输入"
                                v-model="form.actionEvaluation"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="进度安排"
                            prop="actionProgress"
                            required="true"
                            :label-width="formLabelWidth"
                        >
                            <el-input
                                type="textarea"
                                rows="2"
                                placeholder="请输入"
                                v-model="form.actionProgress"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="协同单位" prop="actionJointDeptId" :label-width="formLabelWidth">
                            <el-cascader
                                @change="handleChange"
                                ref="cascader"
                                filterable
                                clearable
                                v-model="form.actionJointDeptId"
                                :props="defaultProps"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="是否涉密" prop="actionSecret" :label-width="formLabelWidth">
                            <el-radio-group v-model="form.actionSecret">
                                <el-radio label="0" size="large">是</el-radio>
                                <el-radio label="1" size="large">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancle">取消</el-button>
                    <el-button type="primary" @click="saveItem(ruleForm, 1)">保存</el-button>
                    <el-button type="primary" @click="saveItem(ruleForm, 0)">提交</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits, defineProps, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';

let dialogFormVisible = ref(true);
const { proxy } = getCurrentInstance();
let rules = {
    actionYear: [{ required: true, message: '请选择年度', trigger: 'blur' }],
    actionName: [{ required: true, message: '请输入重大任务名称', trigger: 'blur' }],
    actionTarget: [{ required: true, message: '请输入目标体系', trigger: 'blur' }],
    actionWork: [{ required: true, message: '请输入工作体系', trigger: 'blur' }],
    actionPolicy: [{ required: true, message: '请输入政策体系', trigger: 'blur' }],
    actionEvaluation: [{ required: true, message: '请输入评价体系', trigger: 'blur' }],
    actionProgress: [{ required: true, message: '请输入进度安排', trigger: 'blur' }],
};
let formLabelWidth = '125px';
const form = ref({
    actionYear: new Date().getFullYear(),
});
let nameOptions = ref([]);
let cascader = ref({});
let taskList = ref([{ actionName: '', levelDeptList: [{}] }]);
let levelDeptList = ref([{}]);
// const defaultProps = {
//     lazy: true,
//     checkStrictly: true,
//     multiple: true,
//     async lazyLoad(node, resolve) {
//         // console.log(node,);
//         const { level } = node;
//         let data = level > 0 ? node.value : 'top';
//         level + 1;
//         let res = await axios({
//             url: 'front/sysOrganization/getPage',
//             method: 'get',
//             params: {
//                 uacDeptId: data,
//             },
//         }); //.then(  => {
//         if (res) {
//             let nodes = res.map(item => {
//                 return {
//                     label: item.deptName,
//                     value: item.uacDeptId,
//                     leaf: false,
//                     deptId: item.deptId,
//                 };
//             });
//             if (res.length == 0) {
//                 console.log(node);
//                 node.data.leaf = true;
//             }
//             resolve(nodes);
//         }
//         // });
//     },
// };
// 点击联选节点触发事件
function handleChange(index, unitIndex, val) {
    console.log(index, unitIndex);
}
let ruleForm = ref(null);
const saveItem = async (ruleForm, status) => {
    let formData = Object.assign({}, form.value);
    if (status) {
        formData.actionStatus = 1;
        postItem(formData);
    } else {
        await ruleForm.validate((valid, fields) => {
            if (!valid) {
                return;
            }
            formData.actionStatus = 0;
            postItem(formData);
        });
    }
};
function postItem(formData) {
    console.log(formData);
    if (title == '编辑重大任务') {
        proxy.$api.frontAction.update(formData).then(async (res) => {
            console.log(res.code);
            if (res.code === 200) {
                ElMessage({
                    message: '编辑成功',
                    type: 'success',
                });
                emit('success');
            }
        });
    } else {
        proxy.$api.frontAction.add(formData).then(async (res) => {
            console.log(res.code);
            if (res.code === 200) {
                ElMessage({
                    message: '新增成功',
                    type: 'success',
                });

                emit('success', res.data);
            }
        });
    }
}
const getById = () => {
    proxy.$api.frontAction.getById(currentTask.data.actionId).then((res) => {
        if (res.code == 200) {
            form.value = res.data;
        }
    });
};
let emit = defineEmits();
let props = defineProps(['title', 'currentTask']);
const { title, currentTask } = props;
console.log(title, currentTask);
if (title == '编辑重大任务') {
    getById();
}

let cancle = () => {
    emit('close');
};
onMounted(() => {});
</script>

<style scoped lang="stylus">
.form
    padding 16px 18px 24px
    .task
        font-size: 14px;
        color: #333333;
        .title
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: 18px
            & > div
                font-weight: 500;
        .input
            background-color: rgba(20, 146, 255, 0.1);
            padding 16px 0 4px
            margin-bottom: 16px
    .addSameTask2
        text-align: center
        font-weight: 400;
        font-size: 14px;
.warn
    margin-left:14px;
    /deep/ .el-icon
        color: #f00
/deep/ .el-dialog__body
    padding: 0 0
/deep/ .el-icon
    vertical-align: -2px
    color: #1492FF
/deep/ .el-cascader
    width 35vw
</style>
<style lang="less" scoped>
.el-popper.is-pure {
    width: 800px !important;
    overflow: scroll;
}
</style>
