<template>
    <div class="class">
        <div class="toolbar">
            <el-form :model="form" ref="formRef" :inline="true" class="demo-form-inline">
                <div class="left">
                    <el-form-item label="指标名称：" prop="searchValue">
                        <el-input placeholder="请输入" v-model="form.indexName"></el-input>
                    </el-form-item>
                    <el-form-item label="状态：" prop="national">
                        <el-select v-model="form.status">
                            <el-option label="全部" value=""></el-option>
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上报人：" prop="searchValue">
                        <el-input placeholder="请输入" v-model="form.createName"></el-input>
                    </el-form-item>
                </div>
                <div class="right">
                    <el-button type="primary" :disabled="taskData.length === 0" @click="getList(currentActionId)"
                        >查询</el-button
                    >
                    <el-button @click="resetForm">重置</el-button>
                </div>
            </el-form>
        </div>
        <div class="table" id="table">
            <div class="content" id="content">
                <div class="tree-box" id="tree-box">
                    <div class="tree-title">
                        <div>
                            <div class="leftIcon">重大任务列表</div>
                        </div>
                        <UploadExcel
                            v-if="userBtns.rwdr"
                            :path="'/prod-api/front/frontAction/import'"
                            :headers="headers"
                            @uploadEd="getTaskData"
                        />
                        <span v-if="userBtns.listofmajortasks_add" @click="handleAddTask">+新增</span>
                    </div>
                    <div class="tree-search">
                        <el-input
                            v-model="keyWord"
                            @input="searchInput"
                            class="w-50 m-2"
                            placeholder="请输入重大任务名称"
                        >
                            <template #prefix>
                                <el-icon class="el-input__icon"><search /></el-icon>
                            </template>
                        </el-input>
                    </div>
                    <el-tree
                        ref="treeRef"
                        :data="taskData"
                        node-key="actionId"
                        :props="defaultProps"
                        :expand-on-click-node="false"
                        @node-click="handNodeClick"
                        :current-node-key="currentActionId"
                        :filter-node-method="filterNode"
                        highlight-current
                    >
                        <template #default="{ node, data }">
                            <span class="custom-tree-node">
                                <el-popover placement="top-start" :width="200" trigger="hover" :content="node.label">
                                    <template #reference>
                                        <span class="label">{{ node.label }}</span>
                                    </template>
                                </el-popover>
                            </span>
                            <span>
                                <el-dropdown
                                    trigger="click"
                                    placement="bottom-start"
                                    @command="handleCommand($event, node)"
                                >
                                    <el-icon @click="() => editTree(node, data)"><edit /></el-icon>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item command="view"> 查看 </el-dropdown-item>
                                            <el-dropdown-item v-if="userBtns.listofmajortasks_update" command="update">
                                                修改
                                            </el-dropdown-item>
                                            <el-dropdown-item
                                                v-if="userBtns.listofmajortasks_update && taskData.length > 1"
                                                command="upMove"
                                            >
                                                上移
                                            </el-dropdown-item>
                                            <el-dropdown-item
                                                v-if="userBtns.listofmajortasks_update && taskData.length > 1"
                                                command="downMove"
                                            >
                                                下移
                                            </el-dropdown-item>
                                            <el-dropdown-item
                                                v-if="userBtns.listofmajortasks_delete"
                                                command="delete"
                                                class="delete"
                                                >删除</el-dropdown-item
                                            >
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </span>
                        </template>
                    </el-tree>
                </div>
                <div class="tables">
                    <div class="subToolbar">
                        <div class="title">
                            指标列表
                            <span class="warn">
                                <el-alert
                                    title="温馨提醒：涉密信息请进入政务内网数字统战填写"
                                    :closable="false"
                                    type="warning"
                                    show-icon
                                />
                            </span>
                        </div>
                        <div class="btns">
                            <UploadExcel
                                v-if="userBtns.zbdr"
                                :path="`/prod-api/front/frontIndex/import?actionId=${currentActionId}`"
                                :headers="headers"
                                @uploadEd="getList(currentActionId)"
                                :disabled="!currentActionId"
                            />
                            <el-button type="primary" :disabled="!currentActionId" @click="addIndex"
                                >新增指标</el-button
                            >
                            <el-button
                                type="primary"
                                v-if="userBtns.submitReview"
                                :disabled="selection.length < 1"
                                @click="subAudit"
                                >提交审核</el-button
                            >
                        </div>
                    </div>
                    <div class="table">
                        <el-table
                            :data="tableData"
                            header-row-class-name="table-header"
                            size="medium"
                            style="width: 100%; height: 100%"
                            height="calc(100% - 40px)"
                            :header-cell-style="{
                                background: '#F7F8FA',
                                color: '#333333',
                                height: '54px',
                                'font-size': '14px',
                            }"
                            @selection-change="selection = $event"
                            :default-sort="{ prop: 'createTime', order: 'descending' }"
                        >
                            <el-table-column type="selection" width=""> </el-table-column>
                            <el-table-column fixed label="序号" type="index" width="80">
                                <template #default="scope">
                                    <span>{{ (form.page - 1) * form.size + scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="indexName" label="指标名称" width=""> </el-table-column>
                            <el-table-column prop="indexType " label="任务类型">
                                <template #default="{ row }">
                                    <span v-if="row.indexType == 0" slot="reference">定量任务</span>
                                    <span v-if="row.indexType == 1" slot="reference">定性任务</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createName" label="上报人" width="80"> </el-table-column>
                            <el-table-column prop="indexNum" label="目标值" width="80" class-name="textCenter">
                            </el-table-column>
                            <el-table-column prop="unitNameAndOrgNum" label="责任单位及目标值" min-width="200">
                            </el-table-column>
                            <!-- <el-table-column prop="" label="当前审核环节" min-width="200">
                                <template #default="scope">
                                    <div>
                                        {{ currentData.currentNodeName }}({{ currentData.currentNodePersonName }})
                                    </div>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                v-if="envType === 'intranet'"
                                prop="classification"
                                label="密级"
                                min-width="200"
                            >
                                <template #default="scope">
                                    <div v-if="scope.row.actionSecret === '0'">
                                        {{ classificationMap[scope.row.classification] }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="status " label="状态" width="" class-name="textCenter">
                                <template #default="{ row }">
                                    <div>
                                        <span
                                            :class="['status', 'status' + row.status]"
                                            v-if="row.status == '0'"
                                            slot="reference"
                                            >• 草稿</span
                                        >
                                        <span
                                            :class="['status', 'status' + row.status]"
                                            v-if="row.status == '1'"
                                            slot="reference"
                                            >• 审核中</span
                                        >
                                        <span
                                            :class="['status', 'status' + row.status]"
                                            v-if="row.status == '2'"
                                            slot="reference"
                                            >• 执行中</span
                                        >
                                        <span
                                            :class="['status', 'status' + row.status]"
                                            v-if="row.status == '3'"
                                            slot="reference"
                                            >• 已完成</span
                                        >
                                        <span
                                            :class="['status', 'status' + row.status]"
                                            v-if="row.status == '4'"
                                            slot="reference"
                                            >• 审核驳回</span
                                        >
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="175" fixed="right" class-name="control textCenter">
                                <template #default="scope">
                                    <el-button type="text" size="small" @click="openDetails(scope.row)">查看</el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        v-if="[0, 4].indexOf(scope.row.status) > -1"
                                        @click="editRow(scope.row)"
                                        >编辑</el-button
                                    >
                                    <el-button type="text" size="small" v-if="[0, 4].indexOf(scope.row.status) > -1">
                                        <el-popconfirm title="确定删除吗？" @confirm="handleDel(scope.row)">
                                            <template #reference>
                                                <span style="color: red" slot="reference">删除</span>
                                            </template>
                                        </el-popconfirm>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="pagination">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="form.page"
                            :page-sizes="[10, 15, 30, 50, 100]"
                            :page-size="form.size"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <add-task
            v-if="showAddTask"
            @success="success"
            :title="taskDialogName"
            :currentTask="currentForm"
            @close="showAddTask = false"
        ></add-task>
        <AddIndexs
            v-if="showAddIndex"
            :formData="indexFormData"
            :title="indexTitle"
            @success="indexSuccess"
            @close="indexClose"
        />
    </div>
</template>

<script setup>
import {
    ref,
    reactive,
    computed,
    watch,
    onMounted,
    onUnmounted,
    onBeforeUpdate,
    getCurrentInstance,
    nextTick,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox, ClickOutside as vClickOutside } from 'element-plus';
import addTask from '@/views/taskManagement/components/addTask';
import AddIndexs from '@/views/taskManagement/components/addIndexs';
import UploadExcel from '@/components/uploadExcel';
let headers = {
    Authorization: localStorage.token,
};
const store = useStore();
const { proxy } = getCurrentInstance();
let timer = setInterval(() => {
    myTimer();
}, 500);
const envType = computed(() => {
    return process.env.VUE_APP_API_ENV_IRONMENT;
});
const classificationMap = {
    1: '秘密',
    2: '机密',
};
function myTimer() {
    let token = localStorage.getItem('token');
    if (token) {
        getTaskData();
    }
    token ? clearInterval(timer) : '';
}
let roles = store.state.user.roles;
const isAdmin = computed(() => {
    return roles.indexOf('admin') > -1 || roles.indexOf('administrator') > -1;
});

function isDisables(role) {
    let res = true;
    role.forEach((e) => {
        if (roles.indexOf(e) > -1) {
            res = false;
        }
        return res;
    });
    return res;
}
// 左侧目录菜单数可否编辑
const leadDeptEditAble = computed(() => {
    return function (level) {
        let res = true;
        if (
            level <= 2 &&
            store.state.user.roles.indexOf('lead-dept') !== -1 &&
            store.state.user.roles.indexOf('administrator')
        ) {
            res = false;
        }
        console.log('editAble', level, store.state.user.roles);
        return res;
    };
});
const treeEditable = computed(() => {
    return function (level) {
        let res = false;
        // 如果只有数据维护人角色
        if (store.state.user.roles.includes('administrator') || store.state.user.roles.includes('admin')) {
            // console.log('treeEditable-有管理员角色')
            res = true;
        } else if (
            (store.getters.roles.length === 1 && store.getters.roles[0] == 'protect') ||
            store.getters.roles.length === 0
        ) {
            // console.log('treeEditable-(只有数据维护人角色|没有任何角色)')
            res = false;
        } else if (store.state.user.roles.includes('lead-dept') && level <= 1) {
            // console.log('treeEditable-牵头人')
            res = false;
        } else {
            res = true;
        }
        // console.log('treeEditable',level,res)
        return res;
    };
});

let selection = ref([]);
const treeRef = ref(null);
const currentActionId = ref(''); //当前选中任务id
let currentData = ref({}); //当前选中任务数据
let showAddIndex = ref(false);
let indexFormData = ref({});
let indexTitle = ref('');
const form = ref({
    status: '',
    indexCreateName: '',
    page: 1,
    size: 10,
    indexName: '',
    actionId: '',
});
let keyWord = ref('');
let isDraft = ref(false);
const defaultProps = {
    children: 'children',
    label: 'actionName',
    actionId: 'actionId',
};
let total = ref(0);
const taskData = ref([]);
const tableData = ref([]);
const options = [
    {
        value: '0',
        label: '草稿 ',
    },
    {
        value: '1',
        label: '审核中',
    },
    {
        value: '2',
        label: '执行中',
    },
    {
        value: '3',
        label: '已完成',
    },
    {
        value: '4',
        label: '审核驳回',
    },
];

const getList = (actionId) => {
    //获取指标列表
    form.value.actionId = actionId || '';
    proxy.$api.frontIndex.getPage(form.value).then((res) => {
        if (res.code == 200) {
            tableData.value = res.data.records;
            total.value = res.data.total;
        }
    });
};
function getTaskData(name) {
    //获取任务列表
    proxy.$api.frontAction.getPage({ size: -1, page: -1, actionName: name || '' }).then((res) => {
        if (res.code == 200) {
            taskData.value = res.data.records;
            console.log(treeRef.value);
            // !currentActionId.value用来判断指标新增/编辑之后是否携带id
            if (res.data.records.length > 0 && !currentActionId.value) {
                getList(res.data.records[0].actionId);
                currentActionId.value = res.data.records[0].actionId;
                currentData.value = res.data.records[0];
                nextTick(() => refreshNode(res.data.records[0].actionId));
            } else {
                // getList(currentActionId.value);
                nextTick(() => refreshNode(currentActionId.value));
                let path = route.path; //清楚路由id
                router.push(path);
            }
        } else {
            ElMessage.error(res.msg);
        }
    });
}

const editTree = (node, data) => {
    // console.log(node, data);
    currentActionId.value = data.actionId;
    isDraft.value = data.actionStatus == 1 ? true : false;
};
let filterNode = (value, data) => {
    if (!value) return true;
    return data.label.indexOf(value) !== -1;
};
const handNodeClick = (data) => {
    // console.log(data);
    currentData.value = data;
    currentActionId.value = data.actionId;
    isDraft.value = data.actionStatus == 1 ? true : false;
    // proxy.$api.frontIndex.getIndexesByActionId(data.actionId).then((res) => {
    //     if (res.code == 200) {
    //         tableData.value = res.data;
    //     }
    // });
    getList(data.actionId);
};

let common = ref('');
let showAddTask = ref(false);
let currentForm = ref({});
let taskDialogName = ref('');
function handleAddTask() {
    taskDialogName.value = '新增重大任务';
    router.push({
        path: 'addOrEditTask',
        query: {
            title: '新增重大任务',
            // id: currentActionId.value,
        },
    });
    // showAddTask.value = true;
}
function searchInput(val) {
    getTaskData(keyWord.value);
}
const handleCommand = (comm, node) => {
    console.log(comm, node);
    common.value = comm;
    if (comm === 'delete') {
        proxy.$api.frontIndex.getIndexNumberByActionId(node.data.actionId).then((res) => {
            if (res.data && res.data > 0) {
                ElMessage({
                    message: '系统检测到该任务下已创建指标，请先删除指标，再删除重大任务。',
                    type: 'warning',
                });
            } else {
                ElMessageBox.alert('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        proxy.$api.frontAction.delete(node.data.actionId).then((res) => {
                            if (res.code == 200) {
                                ElMessage({
                                    message: '删除成功',
                                    type: 'success',
                                });
                                getTaskData();
                            }
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: '取消了删除',
                            type: 'warning',
                        });
                    });
            }
        });
    }
    if (comm === 'view') {
        router.push({
            path: 'addOrEditTask',
            query: {
                title: '查看重大任务',
                id: node.data.actionId,
            },
        });
    }
    if (comm === 'update') {
        proxy.$api.frontIndex.getIndexesNumberNotDraftAndVerifyBackByActionId(node.data.actionId).then((res) => {
            if (res.data == 0) {
                router.push({
                    path: 'addOrEditTask',
                    query: {
                        title: '编辑重大任务',
                        id: node.data.actionId,
                    },
                });
            } else {
                ElMessage({
                    message: '该任务已分解指标并提交审批，请驳回后进行修改。',
                    type: 'warning',
                });
            }
        });
    }
    if (comm === 'upMove' || comm === 'downMove') {
        let type;
        comm === 'upMove' ? (type = 1) : (type = 0);
        let form = {
            moveType: type,
            actionId: node.data.actionId,
        };
        proxy.$api.frontAction.actionMove(form).then((res) => {
            if (res.code == 200) {
                ElMessage.success(`移动成功!`);
                getTaskData();
            } else {
                ElMessage.error(res.msg);
            }
        });
    }
};
// 指标删除
function handleDel(row) {
    proxy.$api.frontIndex.delete(row.indexId).then((res) => {
        ElMessage({
            message: '删除成功',
            type: 'success',
        });
        getList(currentActionId.value);
    });
}
let formData = ref({});
// 新增指标
function addIndex() {
    indexFormData.value = {
        actionId: currentActionId.value,
    };
    indexTitle.value = '新增指标';
    showAddIndex.value = true;
}
// 提交审核
function subAudit() {
    let ids = selection.value.map((e) => e.indexId);
    if (!ids) {
        ElMessage({
            message: '请先选择指标',
            type: 'warning',
        });
        return;
    }
    proxy.$api.frontIndex.getIndexesNumberNotDraftAndVerifyBackByActionId(selection.value[0].actionId).then((res) => {
        if (res.data == 0) {
            router.push({
                path: 'addOrEditTask',
                query: {
                    title: '编辑重大任务',
                    id: currentActionId.value,
                },
            });
        } else if (res.data >= 1) {
            ElMessageBox.alert('该操作会将此任务下的所有指标重新审核，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    router.push({
                        path: 'addOrEditTask',
                        query: {
                            title: '编辑重大任务',
                            id: currentActionId.value,
                        },
                    });
                })
                .catch(() => {
                    ElMessage({
                        message: '取消了提交',
                        type: 'warning',
                    });
                });
        }
    });
}
function indexSuccess(formData, id) {
    showAddIndex.value = false;
    getList(id);
}
const openDetails = (row, tab) => {
    indexFormData.value = row;
    indexTitle.value = '查看指标';
    showAddIndex.value = true;
};
const editRow = (row) => {
    indexFormData.value = row;
    indexTitle.value = '编辑指标';
    showAddIndex.value = true;
};

let handleCurrentChange = (val) => {
    form.value.page = val;
    getList(currentActionId.value);
};
let handleSizeChange = (val) => {
    form.value.size = val;
    getList(currentActionId.value);
};
const router = new useRouter();
const route = new useRoute();
function success(res) {
    showAddTask.value = false;
    if (!res.actionStatus) {
        ElMessageBox.alert('是否新增指标?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
            .then(() => {
                router.push({
                    path: 'addOrEditTask',
                    query: {
                        title: '新增指标',
                        id: res.actionId,
                    },
                });
            })
            .catch(() => {
                getTaskData();
            });
    }
}
function indexClose() {
    showAddIndex.value = false;
    getList(currentActionId.value);
}

// 增删改查之后选中某个节点
function refreshNode(id) {
    if (id) {
        let childNode = treeRef.value.getNode(id);
        treeRef.value.setCurrentKey(childNode);
    }
}

const resetForm = () => {
    form.value = {
        page: 1,
        size: 10,
        keyWord: '',
        status: '',
        createName: '',
        indexName: '',
        actionId: '',
    };
    getList(currentActionId.value);
};
// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
onMounted(() => {});
onBeforeUpdate(() => {
    clearInterval(timer);
});
watch(
    route,
    (newValue, oldValue) => {
        // console.log(route, newValue, oldValue);
        if (newValue && route.query.id) {
            currentActionId.value = route.query.id;
        }
    },
    { deep: true, immediate: true }
);
</script>
<style lang="stylus" scoped>
.class {
	height: calc(100% - 32px);

	.tabs {
		display: flex;
		height: 50px;
	}

    .toolbar {
        display: flex;
        align-items: center;
        background: #fff;
        height: 60px;
        padding: 0 24px;

        /deep/ .el-form {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .el-form-item {
                margin-bottom: 0;
            }
        }
    }

	.table {
		// display: flex;
		height: calc(100% - 72px);
		padding-top: 12px;
		.content {
            display: flex
			box-sizing: border-box;
            height:100%;

			.tree-box {
                width:240px;
                margin-right: 16px;
                background: #FFFFFF;
                padding: 16px 12px;
                overflow-y: auto;
                .tree-search{
                    margin-bottom 12px
                }
                .tree-title {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 14px;
                    &>span{
                        color: #1492FF;
                        cursor pointer
                    }
                    /deep/ .el-button {
                        width: 40px;
                        height: 24px;
                        background: #FFFFFF;
                        border: 1px solid #1492FF;
                        color: #1492FF;
                        border-radius: 4px;
                        font-size: 12px;
                    }
                    .leftIcon {
                        &::before{
                            content:' ';
                            display:inline-block;
                            width: 16px;
                            height: 16px;
                            background: url('~@/assets/img/task.png')no-repeat;
                            vertical-align: -3px;
                            margin-right: 7px;
                        }
                    }
                }
            }
			.tables {
                // flex:1;
                width: calc(100% - 240px)
				background: #fff;
				padding: 16px 24px 6px;

				.table {
					height: calc(100% - 110px);
				}
				.table-header {
					height: calc(100% - 40px);
				}
				.subToolbar {
					height: 50px;
                    display: flex;
                    justify-content: space-between
                    align-items: center
					overflow: hidden;
				}
                .title{
                    display: flex;
                    align-items: center
                    font-weight: 500;
                    font-size: 14px;
                    .warn{
                        margin-left:14px;
                    }
                }
				.btns {
					display: flex;
					justify-content: flex-end;
				}
                /deep/ .el-button {
                    margin-left: 0px;
                }
                /deep/ .el-button--primary {
                    margin-left: 8px;
                }
                .status{
                    display: inline-block
                    width: 68px;
                    height: 24px;
                    text-align: center
                    line-height: 24px
                    &.status2{
                        background: rgba(100, 183, 255, 0.2);
                        color: #1492FF;
                    }
                    &.status3{
                        background: rgba(68, 203, 87, 0.2);
                        color: #44CD40;
                    }
                    &.status1{
                        background: rgba(255, 176, 81, 0.2);
                        color: #FF8B00;
                    }
                    &.status4{
                        background: rgba(255, 127, 127, 0.2);
                        color: #ff5c5c;
                    }
                    &.status0{
                        background: rgba(216, 216, 216, 0.2);
                        color: #999999;
                    }
                }
			}
		}
	}
	.pagination {
		height: 40px;
		padding-top: 10px;
		background: #fff;
		text-align: right;
		padding-right: 24px;
	}
}
</style>
<style lang="stylus" scoped>
/deep/ .el-tree-node__content {
	height: 44px;
}

/deep/ .el-tree-node:focus>.el-tree-node__content {
	background: rgba(21, 145, 255, 0.1);
	color: #1591FF;
}
.custom-tree-node {
	width: 70%;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;

	.label {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
/deep/ .el-dropdown-menu{
        color: #FD3939 ;
    .el-dropdown-menu__item{
        color: #FD3939 !important;
    }
}
/deep/ .el-popover .el-popper {
	min-width: 70px;
}
</style>
