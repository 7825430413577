<template>
    <div>
        <el-upload
            class="upload-demo"
            :action="path"
            :disabled="disabled"
            :headers="headers"
            :data="data"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-progress="handleProgress"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
            :before-remove="beforeRemove"
            multiple
            :on-exceed="handleExceed"
            accept=".xlsx,.xls"
        >
            <el-button :disabled="disabled" :loading="importLoading" class="add" :type="buttonType">{{
                label
            }}</el-button>
        </el-upload>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '导入',
        },
        path: String,
        buttonType: {
            type: String,
            default: 'primary',
        },
        data: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Object,
            default: {
                Authorization: localStorage.token,
            },
        },
    },
    components: {},
    data() {
        return {
            importLoading: false,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        console.log(this.data, this.headers);
    },
    methods: {
        // 文件上传
        handleRemove(file, fileList) {},
        handlePreview(file) {},
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
                    files.length + fileList.length
                } 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        handleError() {
            this.importLoading = false;
            this.$message.error('上传文件失败');
            this.$emit('uploadEd');
        },
        handleSuccess(data) {
            console.log(data);
            if (data.code == '10401' || data.code == '500') {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.importLoading = false;
            this.$emit('uploadEd');
        },
        handleProgress() {
            this.importLoading = true;
        },
        beforeAvatarUpload(file) {
            let type = file.name.split('.')[file.name.split('.').length - 1];
            console.log(type);
            const isExcel = type === 'xls' || type === 'xlsx';
            if (!isExcel) {
                this.$message.error('上传文件只能是 excel 格式!');
            }
            return isExcel;
        },
    },
};
</script>

<style scoped lang="stylus">
.upload-demo {
    margin 0 10px
}
</style>
